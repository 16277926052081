/* You can add global styles to this file, and also import other style files */

/* Importing Bootstrap SCSS file. */
@import '~bootstrap/scss/bootstrap';

@import "~leaflet/dist/leaflet.css";

@import "~@ng-select/ng-select/themes/default.theme.css";

@import "ag-grid-community/dist/styles/ag-grid.css";
@import "ag-grid-community/dist/styles/ag-theme-alpine.css";

@import "primeicons/primeicons.css";
@import "primeng-lts/resources/primeng.min.css";
@import "primeng-lts/resources/themes/saga-blue/theme.css";

//@import "swimlane/ngx-dnd/release/index.css";

@font-face {
  font-family: Futura-MD-BT-Bold;
  src: url('./assets/fonts/futura_md_bt_bold.woff') format('woff');
}
@font-face {
  font-family: Verdana-Bold;
  src: local('Verdana') format('woff');
  font-weight: bolder;
}

:root{
  //font-size: 16px;
  font-size: 14px;
  --gl-yellow: #FFCC29;

  --gl-body-background-color: rgb(237, 232, 232);
  --gl-danger-red: #f9524d;
  --gl-vivid-red-alpha: #ff0004c5;
  --gl-vivid-red: #c13133;
  --gl-enable-green: #00fa9a;
  --gl-enable-green-alfa: #1eff00c5;
  --gl-purple-blue: #686c96;
  --gl-purple-blue-dark: #21265c;
  --gl-gray-blue: #8ca2c9;
  --gl-dark-gray-blue: #51586b;
  --gl-blue-dark-hover: #121928;
  //--gl-blue-dark: #2d4064;
  --gl-blue-dark: #33486f;
  --gl-blue-electric: #33486f;
  --gl-blue-electric-alpha: #0059ffc5;
  --gl-blue-dark-alpha: rgba(51, 72, 111, 0.85);
  --gl-blue: #48659c;
  --gl-light-blue: #30a9d9 ;
  --gl-light-blue-disabled: #71dbff;
  --gl-light-blue-hover: #0078A8;
  --gl-lighter-gray: #e3e3e3;
  --gl-lighter-gray-disabled: #f5f5f6;
  --gl-light-blue-alpha: rgba(33,150,243,0.1);
  --gl-light-green-alpha: rgb(123 245 46 / 50%);
  --gl-light-orange-alpha: rgba(255, 139, 37, 0.5);
  //--gl-light-blue-selected-alpha: rgba(33,150,243,0.4);
  --gl-light-blue-selected-alpha: #bcdffb;
  --gl-light-gray: #f2f2f2;
  --gl-light-gray-hover: #dedede;
  --gl-gray: #c4c4c9;
  --gl-gray-hover: #646464;
  --gl-gray-subtitle: #dbdfe0;
  --gl-dark-gray: #727176;

  --gl-gray-border: #b0afb5;

  --gl-underline-table-row: #9c9ba0;

  --gl-reporte-header-background: #fff;
  --gl-reporte-header-color: var(--gl-blue-dark);
  --gl-reporte-subheader-background: #a6a6a6;
  --gl-reporte-subheader-color: #fff;
  --gl-reporte-subheader-content-background: #eeece1;
  --gl-reporte-subheader-content-color: #000;

  //--pm-general-header-height: 3.5rem;
  --pm-general-header-height: 4rem;
  --pm-vehiculos-header-height: 0rem;
  //--pm-alertas-header-height: 0rem;
  --pm-alertas-header-height: var( --pm-general-header-height);
  //--pm-geocercas-header-height: var( --pm-general-header-height);
  --pm-geocercas-header-height: 0rem;
  --pm-circular-geofences-header-height: 0rem;
  --pm-polyline-geofences-header-height: 0rem;
  --pm-geopuntos-header-height: 0rem;
  --pm-historial-header-height: var( --pm-general-header-height);
  --pm-dashboard-header-height: var( --pm-general-header-height);
  --pm-auditoria-header-height: var( --pm-general-header-height);
  --pm-subcuentas-header-height: var( --pm-general-header-height);
  --pm-drivers-header-height: var( --pm-general-header-height);
  --pm-notificaciones-header-height: var( --pm-general-header-height);
  --pm-configuracion-header-height: var( --pm-general-header-height);

  --navbar-height: 4.75rem;
  --footbar-height: 2rem;
  //--row-busqueda-height: 4.375rem;
  //--panel-monitoreo-header-height: 3.5rem;
  //--row-busqueda-height: 3.5rem;
  --row-busqueda-height: var(--pm-general-header-height);
  --treetable-header-height: 2.625rem;

  --gl-btn-border-radius: 0.5rem;
  --gl-btn-y-padding: 0.375rem;

  --gl-panel-padding-left: 2.5rem;

  --navbar-background-color: #fff;

  --general-font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";

}

html, body { height: 100%; }
body {
  margin: 0;
  font-family: var(--general-font-family);
  *:not(i, .fa, .pi, .overlay *){
    font-family: var(--general-font-family);
  }
}

// .leaflet-tooltip-left:before {
//   right: 0;
//   margin-right: -12px;
//   border-left-color: rgba(0, 0, 0, 0.4);
// }
// .leaflet-tooltip-right:before {
//     left: 0;
//     margin-left: -12px;
//     border-right-color: rgba(0, 0, 0, 0.4);
//     }
// .leaflet-tooltip-own {
//     position: absolute;
//     padding: 4px;
//     background-color: rgba(0, 0, 0, 0.4);
//     border: 0px solid #000;
//     color: #000;
//     white-space: nowrap;
//     -webkit-user-select: none;
//     -moz-user-select: none;
//     -ms-user-select: none;
//     user-select: none;
//     pointer-events: none;
//     box-shadow: 0 1px 3px rgba(0,0,0,0.4);
// }

div.leaflet-label-right {
  -webkit-user-select: auto;
  -moz-user-select: auto;
  background-color: rgba(255, 255, 255, 0.81);
  border-width: 0.125rem;
  border-style: dotted;
  border-color: #585858;
  padding: 0.125rem !important;
  font-size: 0.5625rem;
}

div.leaflet-label-right:before {
-webkit-user-select: auto;
-moz-user-select: auto;
content: none;
}

// div.leaflet-tooltip-own {
//   /* border-style: hidden;
//   background: none; */
//   background-color: rgba(255, 255, 255, 0.81);
//   border-width: 2px !important;
//   // border-style: dashed !important;
//   border-color: #000000;
//   left: 17px;
//   top: -17px;
//   padding: 2px !important;
//   font: 9px "Helvetica Neue", Arial, Helvetica, sans-serif !important;
// }

div.leaflet-tooltip-own {
  background-color: rgba(255, 255, 255, 0.90);//rgba(252, 250, 250, 0.95);
  border-width: 0.125rem;
  padding: 0.125rem !important;
  font-size: 0.5625rem !important;


  background-clip: padding-box;
  border-color: rgba(0,0,0,0.25); //rgba(0, 0, 0, 0.1);
  border-radius: 0.25rem;
  border-style: solid;
}

.p-card-header{
  padding-left: 1rem;
  background-color: var(--gl-blue-dark);
  height: 3rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.bg-subtitle{
  padding: 0.4rem 1rem;
  width: 100%;
  background-color: var(--gl-lighter-gray);
  color: #000000;
}

.p-dropdown:not(.p-disabled).p-focus, .p-inputtext:enabled:focus{
  outline: none;
  box-shadow: none;
}

.btn-gl-light-blue,
.nav-item.active .btn-navbar,
.show > .btn-navbar.dropdown-toggle{
  background-color: var(--gl-blue-dark) !important;
  border-color: var(--gl-blue-dark) !important;
  color: #ffffff !important;
}

.btn-navbar{
  background-color: transparent;
  border: none;
}
.btn-navbar:focus{
  outline: none !important;
  box-shadow: none !important;
}
.btn-navbar:focus:not(.btn-navbar.active, .btn-navbar:active, .show > .btn-navbar.dropdown-toggle){
  color: #000;
  background: none;
}

li:hover .btn-navbar:not(.btn-navbar.active, .btn-navbar:active, .show > .btn-navbar.dropdown-toggle.active){
  background-color: var(--gl-light-gray-hover);
  border: none;
  color: #000000;
}

.btn-gl-dark{
  background-color: var(--gl-blue-dark);
  color: #fff;
}

.btn-gl-red{
  background-color: var(--gl-vivid-red);
  color: #fff;
}

.btn-circle{
  border-radius: 50%;
  border: none !important;
  padding: 0;
}

.btn-gl-clear{
  background-color: inherit;
  border: none;
}

i.icon-gl-blue{
  color: var(--gl-blue);
}

button[disabled] i.icon-gl-blue{
  color: var(--gl-gray);
}

thead.visible-on-scroll {
  position: relative;
  z-index: 1;

  > tr {
    position: sticky;
    top: 0;
  }
}

.fa.fa-minus-circle:before {
  color: var(--gl-danger-red);
}

.flex-none {
  flex: none;
}

.dropdown-item.active,
.dropdown-item:active{
  background-color: var(--gl-blue-dark);
}

.p-input-icon-right > input[type="text"]{
  height: 100%;
}

.dialog-body-container{
  font-size: 0.75rem;

  .dialog-form-container {
    flex-direction:column;
    row-gap:0.75rem;
  }
}

.p-dialog.p-component{
  border-radius: 0.75rem;
  .p-dialog-header{
    background-color: var(--gl-blue-dark);
    color: #fff;
    height: 2rem;
    border-radius: 0.75rem 0.75rem 0 0;
    strong{
      font-size: 0.875rem;
    }

    .p-dialog-header-icon.p-dialog-header-close{
      color: #fff !important;
      &:hover{
        background-color: var(--gl-danger-red);
      }
    }
  }
  .p-dialog-content{
    padding: 1.25rem 3rem 0.0625rem 3rem;
  }
  .p-dialog-footer{
    padding-top: 1.5rem;
    border-radius: 0 0 0.75rem 0.75rem;
  }

  .btn-circle{
    width: 2rem;
    height: 2rem;
  }

}

p-dialog {
  &.error-dialog{
    .p-dialog.p-component .p-dialog-header {
      background-color: #000;
    }
  }
}

p-confirmdialog{
  &.gl-confirm-dialog .p-dialog.p-component{
    .p-dialog-header {
      background-color: #fff;
      color: #000;
      justify-content: center;
      height: auto;
      padding-top: 2rem;
      padding-bottom: 0rem;
      span, h1, h2, h3{
        font-weight: bold;
        margin: 0;
      }
      .p-dialog-header-icons button{
        position: absolute;
        right: 1rem;
        top: 1rem;
        &:not(:hover){
          color: #000 !important;
        }
      }
    }
    .p-dialog-content{
      font-size: 1.125rem;
      padding: 1rem 1.6rem .3rem;
      justify-content: center;
      span, h1, h2, h3{
        margin: 0;
      }
    }
    .p-dialog-footer{
      display: flex;
      padding-top: calc(1.25rem + 0.3125rem);
      justify-content: center;
      gap: 1rem;
      button{
        font-size: 1rem;
        font-weight: bold;
        padding: .625rem 1.1rem;
      }
    }
  }
}

.swal2-container{
  .swal2-popup.swal2-modal{
    border-radius: 0.75rem;
    .swal2-title{
      padding-top: 2rem;
      font-size: 1.75rem;
      font-weight: bold;
      color: #000;
    }
    .swal2-html-container{
      margin: 1rem 1.6rem .3rem;
      font-size: 1.125rem;
    }
    .swal2-actions{
      font-size: 1rem;
      margin-top: 1.25rem;
      gap: 1rem;
      button{
        border-radius: var(--gl-btn-border-radius);
        border: none;
        margin: 0.3125rem 0;
      }
      .swal2-styled.swal2-confirm{
        background-color: var(--gl-light-blue);
      }
      .swal2-styled.swal2-cancel{
        background-color: var(--gl-lighter-gray);
        color: #000;
      }
      .swal2-styled.swal2-confirm:focus,
      .swal2-styled.swal2-cancel:focus{
        box-shadow: none;
      }
    }
  }
}

.gl-input-container, .gl-row-underline{
  input:not(.p-multiselect-filter, [type="checkbox"]),
  select,
  p-multiselect > .p-multiselect.p-component,
  p-dropdown > .p-dropdown.p-component,
  p-inputnumber .p-inputnumber-button,
  p-calendar .p-datepicker-trigger.p-button-icon-only{
    border: none !important;
    background-color: inherit !important;

    span.p-button-icon {
      color: #6c757d;
    }
  }

  .p-inputnumber-button:not([disabled]):hover,
  .p-datepicker-trigger.p-button-icon-only:not([disabled]):hover{
    background-color: var(--gl-light-gray-hover) !important;
    border-radius: 3px !important;
  }

  input:not([disabled]){
    text-shadow: 0 0 #000;
  }
}

.gl-row-underline{
  border-bottom: 1px solid var(--gl-gray-border);
  /* padding-bottom: 0.25rem; */
}

.gl-middleline{
  position: relative;
  > .row {
    --gap: 0.625rem;
    gap: var(--gap);
    align-items: end;
    > .col-6:first-child{
      padding-right: calc(calc(var(--bs-gutter-x) * .5) + calc(var(--gap) / 2));
      margin-right: calc(var(--gap) * -1 / 2);
    }
    > .col-6:nth-child(2){
      padding-left: calc(calc(var(--bs-gutter-x) * .5) + calc(var(--gap) / 2));
      margin-left: calc(var(--gap) * -1 / 2);
    }
  }
  &::after{
    content: "";
    position: absolute;
    height: 100%;
    width: 1px;
    background-color: var(--gl-gray-border);
    left: 50%;
    top: -0.375rem;
  }
}

.gl-row-underline .gl-middleline::after,
.gl-row-underline.gl-middleline::after{
  top: 0;
  height: calc(100% - 0.375rem);
}

.gl-out-middleline{
  margin-top: 0.75rem; //Corresponde al row-gap de .dialog-form-container
}

.gl-listbox-container{
  .p-listbox{
    border: none;
  }
  .p-listbox-header{
    margin-bottom: 0.625rem;
    border-radius: 0;
  }

  .p-listbox-header,
  .p-listbox-list-wrapper{
    border: 1px solid var(--gl-gray-border);
  }

  .p-listbox .p-listbox-list .p-listbox-item{
    font-size: 0.75rem;
    padding: 0.375rem 0.75rem;

    .car-item img{
      margin-right: 0.25rem !important;
    }
  }
}

.btn-gl,
.btn-gl-cancel,
.btn-gl-save,
.btn-gl-red,
.btn-gl-neutral,
.btn-dark-hover:hover::after{
  border-radius: var(--gl-btn-border-radius);
  padding-top: var(--gl-btn-y-padding);
  padding-bottom: var(--gl-btn-y-padding);
}

.btn-gl-cancel{
  color: #000;
  background-color: var(--gl-lighter-gray);
  border: none;
  &[disabled]{
    background-color: var(--gl-light-gray-disabled);
    border: 1px solid var(--gl-lighter-gray);
  }
}

.btn-gl-save{
  color: #fff;
  background-color: var(--gl-light-blue);
  border: none;
  &[disabled]{
    background-color: var(--gl-light-blue-disabled);
    border: 1px solid var(--gl-lighter-gray);
  }
}

.btn-gl-neutral{
  color: #000;
  background-color: inherit;
  border: 1px solid var(--gl-light-blue);
}

.btn-dark-hover, .btn-neutral-hover{
  position: relative;
  z-index: 1;
  transition: none;

  &:not(.btn-gl-neutral, [disabled]){
    border: none;
  }

  &:hover::after{
    content: "";
    display: inline-block;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: -1;
  }

  &.btn-circle::after{
    border-radius: 50%;
  }

  &.dropdown-toggle:hover::after{
    border: none;
    margin: 0;
    display: inline-block;
  }
}

.btn-dark-hover:not([disabled]):hover{
  color: #fff;
  &::after{
    background: rgba(0, 0, 0, 0.25);
  }
}

.btn-neutral-hover:not([disabled]):hover{
  &::after{
    background-color: var(--gl-light-blue-alpha);
  }
}

.dropdown-item{
  cursor: pointer;
}

.center-icon{
  display: grid;
  place-items: center;
}

.neutral-icon-color{
  color: var(--gl-gray-border);
}

p-checkbox{
  > div:first-child{
    height: fit-content !important;
    width: fit-content !important;
  }

  .p-checkbox-box{
    outline: none !important;
    box-shadow: none !important;
    span.p-checkbox-icon{
      font-weight: bold !important;
      transition: none;
    }
  }

  .p-checkbox-box:not(.p-disabled){
    background-color: inherit !important;
    border-color: var(--gl-light-blue) !important;
    .p-checkbox-icon{
      color: var(--gl-light-blue) !important;
    }
  }

  .p-checkbox-disabled > .p-checkbox-box{
    background-color: var(--gl-gray) !important;
    border-color: var(--gl-gray) !important;
  }
}



.dropdown-customize-vehicle-panel .png-checkbox-container{
  p-checkbox{
    margin-right: 0.25rem;
  }
  span,label{
    cursor: pointer;
    display: flex;
    align-items: center;
    width: fit-content;
  }
}

.disable-chkbox-behavior span{
  cursor: pointer;
  p-checkbox{
    pointer-events: none;
  }
}


.inline-png-checkbox,
.png-checkbox-container{
  p-checkbox{
    cursor: default;
    .p-checkbox-box{
      height: 1rem;
      width: 1rem;
      span.p-checkbox-icon{
        font-size: 0.75rem;
      }
    }
  }
}

p-inputswitch{
  //--size-handler: 0.0125rem;
  --size-handler: 0.015rem;
  .p-inputswitch{
    height: calc(var(--size-handler) * 70);
    width: calc(var(--size-handler) * 120);
    .p-inputswitch-slider{
      outline: none !important;
      box-shadow: none !important;
      background-color: #fff;
      &::before{
        width: calc(var(--size-handler) * 75);
        height: calc(var(--size-handler) * 75);
        left: calc(var(--size-handler) * -5);
        margin-top: calc(var(--size-handler) * -37.5);
        background-color: #000;

        //Bolita pequeña
        transform: scale(0.7);
      }
    }

  }
  .p-inputswitch.p-inputswitch-checked .p-inputswitch-slider{
    /* background: #d7ead9; */
    background: var(--gl-gray);
    &::before{
      transform: translateX(calc(var(--size-handler) * 73.5));
      background: var(--gl-light-blue);
    }
  }

  .p-inputswitch.p-inputswitch-checked:not(.p-disabled):hover .p-inputswitch-slider{
    /* background: #C1D2C3; */
    background: var(--gl-gray-hover);
  }
  /* .p-inputswitch{
    height: 0.875rem;
    width: 1.5rem;
    .p-inputswitch-slider::before{
      width: 0.625rem;
      height: 0.625rem;
      left: 0.125rem;
      margin-top: -0.3125rem
    }

  }
  .p-inputswitch.p-inputswitch-checked .p-inputswitch-slider::before{
    transform: translateX(0.6125rem);
  } */

}

.pm-config-w-header-rb{
  height: calc(100% + var(--row-busqueda-height));
  position: relative; //for ngxSpinner

  #rowBusqueda{
    padding-top: 0.5rem !important;
  }
}

#panelMonitoreo{
  .panel-izq-table-container{
    table{
      width: 100%;
      vertical-align: middle;
    }

    th,td{
      padding: 0.5rem 0.5rem;
      &:first-child{
        padding: 0.5rem 0.5rem 0.5rem calc(0.5rem + var(--gl-panel-padding-left));
      }
    }

    thead tr{
      background-color: var(--gl-lighter-gray);
      color: var(--gl-dark-gray-blue);
      text-align: center;
    }

    tbody tr{
      background-color: inherit;
      border: none;
      position: relative;
      height: 3.5rem;
      &::after{
        content: "";
        position: absolute;
        bottom: 0;
        right: 0;
        width: calc(100% - calc(0.25rem + var(--gl-panel-padding-left)));
        margin: 0 0.465rem;
        height: 1px;
        //background-color: #e9ecef; //Same color as Treetable border bottom
        background-color: var(--gl-underline-table-row); //Same color as Treetable border bottom
      }
      &:hover{
        background-color: var(--gl-light-blue-alpha);
      }
      &.tr-selected {
        background-color: var(--gl-light-blue-selected-alpha) !important;
      }
    }
  }
}

.p-datepicker-title{
  > select.p-datepicker-month,
  > select.p-datepicker-year{
    border: none;
    /* background-color: var(--gl-light-blue-alpha); */
    padding: 0.5rem;
    border-radius: 0.5rem;
    border: 0.125rem solid var(--gl-lighter-gray);
    cursor: pointer;
    /* option{
      background-color: #fff;
    } */
  }
}

@media screen and (max-width: 850px) {
  .dialog-form-container {
    > .row > .col{
      flex: 0 0 auto;
      width: 100% !important;
    }
  }

  .gl-middleline:not(.gl-permanent-middleline)::after{
    display: none;
  }


}



// style popup events
  // popup


  .popup-container {
    display: flex;
    flex-direction: column; /* Cambiamos a columna para que estén en columnas */
    align-items: flex-end; /* Alineamos las ventanas a la parte inferior */
    position: fixed;
    bottom: 20px;
    right: 20px;
    z-index: 1000;
  }

  .popup-row {
    display: flex;
    margin-bottom: 10px;
  }

.popup-events {
  background-color: #ffffff;
  border-radius: 10px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
  width: 250px;
  display: flex;
  flex-direction: column;
  margin-right: 10px;
}

.header-popup-events {
  background-color: #33486f;
  span {
    color: #ffffff;
  }
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-left: 15px;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  height: 2rem;
}

.title-popup-events {
  flex: 1; /* El título ocupa todo el espacio disponible */
  display: flex;
  align-items: center;
}

.icons-popup-events {
  display: flex;
  align-items: center;
  margin-left: 50px;
}

.icons-popup-events i {
  margin-right: 10px; /* Espacio entre los iconos */
}

.content-popup-events {
  flex: 1; /* Hace que el contenido ocupe todo el espacio disponible */
  height: auto;
}

.map-content-popup-events{
  height: 100px;
}

.info-popup-events {
  background-color: rgba(192, 192, 192, 0.5);
  // text-align: center;
  padding-left: 10px;
  border-top: 1px solid #ccc;
  border-bottom: 1px solid #ccc;
  span {
    color: #ffffff;
  }
}

.footer-popup-events {
  background-color: rgba(192, 192, 192, 0.5);
  /* text-align: center; */
  padding-left: 10px;
  // color: #ffffff;
  span {
    color: #ffffff;
  }
}

.popup-events-toggle {
  margin-left: auto; /* Mueve la "x" hacia la derecha */
  margin-right: 10px;
  cursor: pointer;
}

.multimedia-slider {
  height: 15rem;
  padding-top: 0rem;
}

.highlight-new-event {
  background-color: #bbe5ff !important;
  transition: background-color 0.3s ease !important;
}

.custom-tooltip {
  background-color: rgba(40, 40, 40, 0.9); // Fondo gris oscuro con transparencia
  color: white; // Texto blanco
  text-align: center;
  border-radius: 4px; // Borde redondeado
  padding: 2px 4px; // Ajuste menor del padding para un tamaño más pequeño
  position: absolute;
  z-index: 1000;
  white-space: nowrap; // No dividir el texto en varias líneas
  top: 100%; // Debajo del ícono
  left: 50%; // Centrado horizontalmente
  transform: translateX(-50%) translateY(5px); // Ajusta la posición en X y Y
  font-size: 11px; // Texto más pequeño
  line-height: 1; // Ajusta la altura de línea para reducir el tamaño
  pointer-events: none; // Evita que interfiera con los clics
  border: none; // Elimina el borde
  box-shadow: none; // Elimina la sombra para un aspecto más compacto
  display: inline-flex; // Ajusta el tamaño del tooltip al texto
  align-items: center; // Centra el texto verticalmente
  justify-content: center; // Centra el texto horizontalmente
  margin: 0; // Asegúrate de que no haya margen adicional

  max-width: 150px;
  white-space: normal; // Permite que el texto ocupe varias líneas
  overflow: visible; // Evita que el contenido del tooltip se corte
  word-wrap: break-word; // Rompe palabras largas si es necesario
}

// Ocultar el tooltip por defecto
.tooltip-hidden {
  visibility: hidden;
}

// Mostrar el tooltip cuando se pasa el mouse por el contenedor
.tooltip-container:hover .custom-tooltip {
  visibility: visible;
}

// Estilos para el tooltip contenedor
.tooltip-container {
  position: relative; // Asegura que el tooltip se posicione respecto al ícono
}


//swich dvr or cipia
.glt_switch {
  display: contents;
}

.glt_switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.glt_slider {
  position: relative;
  display: block;
  cursor: pointer;
  top: 0;
  left: calc(50% - 45px);
  bottom: 0;
  width: 90px;
  height: 24px;
  background-color: #ccc;
  -webkit-transition: 0.4s;
  transition: 0.4s;
  border-radius: 6px;
  margin-bottom: 2px;
}

.glt_slider:before {
  position: absolute;
  content: "";
  height: 16px;
  width: 16px;
  left: 4px;
  bottom: 4px;
  background-color: white;
  -webkit-transition: 0.4s;
  transition: 0.4s;
  border-radius: 4px;
}
.glt_slider:after {
  position: absolute;
  height: 18px;
  width: 34px;
  right: 8px;
  bottom: 4px;
  -webkit-transition: 0.4s;
  transition: 0.4s;
  text-transform: uppercase;
}
#glt_checkbox:checked + .glt_slider:after {
  content: "dvr";
}
#glt_checkbox:not(:checked) + .glt_slider:after {
  content: "cipia";
}

#glt_checkbox:checked + .glt_slider {
  background-color: #3d3d3d;
}
#glt_checkbox:not(:checked) + .glt_slider {
  background-color: #272727;
}
#glt_checkbox:disabled + .glt_slider {
  background-color: #181818;
}

#glt_checkbox:focus + .glt_slider {
  box-shadow: 0 0 1px #2196f3;
}

#glt_checkbox:checked + .glt_slider:before {
  -webkit-transform: translateX(54px);
  -ms-transform: translateX(54px);
  transform: translateX(54px);
}
#glt_checkbox:checked + .glt_slider:after {
  -webkit-transform: translateX(-42px);
  -ms-transform: translateX(-42px);
  transform: translateX(-42px);
}

.glt_media {
  display: block;
}

#glt_checkbox:not(:checked) ~ .glt_media_dvr {
  display: none;
}

#glt_checkbox:checked ~ .glt_media_cipia {
  display: none;
}
.glt_media_cipia {
  display: block;
  position: relative;
  left: 50%;
  transform: translateX(-50%);
}
.glt_media_dvr {
  display: block;
  position: relative;
  left: 50%;
  transform: translateX(-50%);
  z-index: 100;
}

